import React from 'react'
import Layout from '../components/Layout'

export default function About() {
    return (
        <Layout>
            <div>
                <h1>About Page</h1>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae obcaecati, expedita veniam dignissimos provident dolor sunt rerum aliquam consectetur nostrum ad tempora minus quas! Inventore a earum temporibus nobis optio!</p>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae obcaecati, expedita veniam dignissimos provident dolor sunt rerum aliquam consectetur nostrum ad tempora minus quas! Inventore a earum temporibus nobis optio!</p>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae obcaecati, expedita veniam dignissimos provident dolor sunt rerum aliquam consectetur nostrum ad tempora minus quas! Inventore a earum temporibus nobis optio!</p>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae obcaecati, expedita veniam dignissimos provident dolor sunt rerum aliquam consectetur nostrum ad tempora minus quas! Inventore a earum temporibus nobis optio!</p>

            </div>
        </Layout>
    )
}
